import { ReactNode } from 'react'

interface CardProps {
  children: ReactNode
}

interface CardRootProps extends CardProps {
  feature?: boolean
  text: string | null
}

interface CardPriceProps {
  price: number
}

interface CardBenefitsProps {
  benefits: string[]
}

enum ICardAssignTypes {
  youtube = 'youtube',
  streamar = 'streamar',
}

interface ICardActionsProps {
  assign: {
    url: string
    description: string
    text: string
    type: ICardAssignTypes
  }[]
  title: string
}

export type { CardProps, CardRootProps, CardPriceProps, CardBenefitsProps, ICardActionsProps }
export { ICardAssignTypes }
