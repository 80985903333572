import { FC } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { formatReal } from '@utils/formats/formats'

import { Title } from '@/components'

interface HeadTitleProps {
  meta: {
    title: string
    description: string
    max: number
    current: number
    status: boolean
  }
}

const Goal: FC<HeadTitleProps> = ({ meta }) => {
  if (!meta.status) return null

  return (
    <section className={'mt-15 mb-10'}>
      <Title>{meta.title}</Title>
      <div className={'text-white'} dangerouslySetInnerHTML={{ __html: meta.description }} />
      <ProgressBar
        className={'mt-3 meta-progress-bar h-8 rounded text-lg'}
        max={meta.max}
        now={meta.current}
        label={`R$ ${formatReal(meta.current)}`}
        animated
      />
    </section>
  )
}

export default Goal
