import ReactGA from 'react-ga4'

interface SendEventProps {
  category: string
  action: string
  label: string
}

const sendEvent = ({ category, action, label, ...rest }: SendEventProps) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    ...rest,
  })
}

export { sendEvent }
