import { FC, useState } from 'react'

import { sendEvent } from '@utils/ga4/ga4'
import { PlayCircle } from 'lucide-react'

interface VideoProps {
  videoId: string
  videoTitle: string
  buttonTitle: string
}

const Video: FC<VideoProps> = ({ videoId, videoTitle, buttonTitle }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const clickToPlay = () => {
    setIsPlaying(true)
    sendEvent({
      category: 'video',
      action: 'click',
      label: 'play-video-de-apoio',
    })
  }

  return (
    <section
      className={'mb-12 border-8 border-violet-800 rounded w-full h-full aspect-video relative'}
    >
      {isPlaying ? (
        <iframe
          className={'w-full aspect-video'}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&amp;controls=0&amp;showinfo=0`}
          title={`${videoTitle}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        ></iframe>
      ) : (
        <div className={'items-center justify-center flex'}>
          <button
            className={
              'bg-neutral-950 bg-opacity-30 w-full h-full absolute z-0 items-center justify-center flex'
            }
            onClick={() => clickToPlay()}
            title={`${buttonTitle}`}
          >
            <PlayCircle size={78} className={'absolute text-white z-10 hover:cursor-pointer'} />
          </button>
          <img
            className={'w-full aspect-video'}
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            alt={`${videoTitle}`}
          />
        </div>
      )}
    </section>
  )
}

export default Video
