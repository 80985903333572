import { ChevronDown } from 'lucide-react'

import './Details.css'

interface DetailsProps {
  details: {
    id: number
    title: string
    description: string
  }[]
}

const Details = ({ details }: DetailsProps) => {
  return details.map((detail) => {
    return (
      <details
        key={detail.id}
        className={'details py-4 border-b bg-white p-4 rounded text-left w-full'}
      >
        <summary
          className={'font-bold text-lg flex justify-between cursor-pointer text-neutral-800'}
        >
          {detail.title}
          <ChevronDown className={'details-arrow text-violet-800'} size={18} />
        </summary>
        <div
          className={'mt-2 text-neutral-800 text-base details-content'}
          dangerouslySetInnerHTML={{ __html: detail.description }}
        />
      </details>
    )
  })
}

export default Details
