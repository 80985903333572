import { FC } from 'react'

import { CardProps } from '@/components/Card/CardInterface'

const CardIcon: FC<CardProps> = ({ children }) => {
  return (
    <span
      className={`mb-3.5 inline-flex bg-gray-100 p-1.5 w-[50px] h-[50px] items-center justify-center rounded-full border border-gray-150 text-3xl`}
    >
      {children}
    </span>
  )
}

export default CardIcon
