import { ICardAssignTypes } from '@/components/Card/CardInterface'
import { SocialLinkEnum } from '@/components/SocialLink/SocialLink.type'
import { api } from '@/services/services'

interface IGetApoioResponse {
  social_links: {
    id: number
    url: string
    type: SocialLinkEnum
    text: string
    description: string
  }[]
  meta: {
    title: string
    description: string
    max: number
    current: number
    status: boolean
  }
  video: {
    id: string
    description: string
    button_title: string
  }
  plans: {
    id: number
    feature: boolean
    feature_text: string | null
    icon: string
    title: string
    price: number
    benefits: string[]
    assign: {
      url: string
      description: string
      text: string
      type: ICardAssignTypes
    }[]
  }[]
  faq: {
    id: number
    title: string
    description: string
  }[]
}

const getApoio = async () => {
  try {
    const response = await api.get<IGetApoioResponse>(`/apoio`)
    return {
      data: response.data,
    }
  } catch {
    return {
      data: [],
    }
  }
}

export { getApoio }
export type { IGetApoioResponse }
