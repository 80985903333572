import { FC } from 'react'
import ReactGA from 'react-ga4'

import { ICardActionsProps, ICardAssignTypes } from '@/components/Card/CardInterface'

const CardActions: FC<ICardActionsProps> = ({ assign, title }) => {
  const eventsGA = (title: string, type: ICardAssignTypes) => {
    ReactGA.event({
      category: 'apoio-click',
      action: 'click',
      label: `clicou para assinar o plano ${title} pelo ${type}`,
    })
  }

  return (
    <div className={'flex gap-3 justify-center flex-col'}>
      {assign.map((item) => (
        <a
          key={item.url}
          href={item.url}
          target={'_blank'}
          rel={'noreferrer'}
          title={item.description}
          className={`text-white p-2.5 rounded-md text-sm ${
            item.type === ICardAssignTypes.youtube ? 'bg-youtube' : 'bg-blue-700'
          } font-medium`}
          onMouseDown={() => eventsGA(title, item.type)}
        >
          {item.text}
        </a>
      ))}
    </div>
  )
}

export default CardActions

export type { ICardAssignTypes }
