import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import * as Sentry from '@sentry/react'

import Support from '@/pages/Support/Support'

import './index.css'

if (import.meta.env.MODE === 'production') {
  disableReactDevTools()
}

const root = createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: 'https://d4429353bbeff982e349fdf1be0ff44b@o4506389548695552.ingest.sentry.io/4506389550661632',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <Support />,
//   },
//   // {
//   //   path: '/apoio',
//   //   element: <Support />,
//   // },
//   // {
//   //   path: '/cursos',
//   //   element: <Course />,
//   // },
// ])

ReactGA.initialize('G-THYHSVZ28F')

root.render(
  <StrictMode>
    <Support />
  </StrictMode>,
)

// Uncomment if you want to see the Lighthouse report in the console
// import reportWebVitals from './reportWebVitals'
// reportWebVitals(console.log)
