import { FC, ReactNode } from 'react'

interface CopyrightProps {
  children: ReactNode
}

const Copyright: FC<CopyrightProps> = ({ children }) => {
  return <footer className={'text-white font-medium text-base mt-8'}>{children}</footer>
}

export default Copyright
