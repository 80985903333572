import { FC, ReactNode } from 'react'

interface TitleProps {
  children: ReactNode
}

const Title: FC<TitleProps> = ({ children }) => {
  return (
    <h2
      className={
        'text-white justify-center font-bold text-2xl uppercase before:w-28 before:h-[1px] flex items-end mb-6 pb-1.5 before:bottom-0 relative before:bg-white before:absolute'
      }
    >
      {children}
    </h2>
  )
}

export default Title
