import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react'

const SocialLinkIcons = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  youtube: Youtube,
}

interface SocialLinkProps {
  socialLinks: {
    id: number
    url: string
    description: string
    type: SocialLinkEnum
  }[]
}

type SocialLinkEnum = keyof typeof SocialLinkIcons

export type { SocialLinkProps, SocialLinkEnum }

export { SocialLinkIcons }
