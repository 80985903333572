import { FC } from 'react'

import { CheckCircle2 } from 'lucide-react'

import { CardBenefitsProps } from './CardInterface'

const CardBenefits: FC<CardBenefitsProps> = ({ benefits }) => {
  const benefitsList = () => {
    return benefits.map((benefit) => {
      return (
        <li key={benefit} className={'text-center flex justify-center gap-x-2 items-center'}>
          <CheckCircle2 size={18} className={'text-violet-700'} />
          <p>{benefit}</p>
        </li>
      )
    })
  }

  if (!benefits.length) return <></>

  return (
    <ul
      className={'text-center flex gap-3.5 flex-col font-medium my-4 text-base justify-center px-6'}
    >
      {benefitsList()}
    </ul>
  )
}

export default CardBenefits
