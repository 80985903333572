import { FC } from 'react'

import { CardRootProps } from './CardInterface'

const CardRoot: FC<CardRootProps> = ({ children, feature, text }) => {
  const borderRadius = feature ? 'rounded-b-md' : 'rounded-md'

  return (
    <section className={'max-w-sm'}>
      {feature && (
        <div
          className={
            'bg-violet-900 rounded-t-md py-3 px-3 font-bold text-white flex gap-2 justify-center uppercase items-center'
          }
        >
          <p>{text}</p>
        </div>
      )}
      <div className={`bg-white text-slate-950 py-6 px-3 ${borderRadius}`}>{children}</div>
    </section>
  )
}

export default CardRoot
