import CardActions from '@/components/Card/CardActions'
import CardBenefits from '@/components/Card/CardBenefits'
import CardIcon from '@/components/Card/CardIcon'
import CardPrice from '@/components/Card/CardPrice'
import CardRoot from '@/components/Card/CardRoot'
import CardTitle from '@/components/Card/CardTitle'

export const Card = {
  Root: CardRoot,
  Actions: CardActions,
  Icon: CardIcon,
  Title: CardTitle,
  Price: CardPrice,
  Benefits: CardBenefits,
}
