import { useEffect, useState } from 'react'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import { EffectFade, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Card, Copyright, Details, HeadTitle, Logo, SocialLink, Title, Video } from '@/components'
import Goal from '@/components/Support/Goal/Goal'
import { useScreenDetector } from '@/hooks'
import { IGetApoioResponse, getApoio } from '@/hooks/useApoio/useApoio'

import './Support.css'

const Support = () => {
  const { isMobile } = useScreenDetector()

  const [apoio, setApoio] = useState<IGetApoioResponse | null>(null)

  useEffect(() => {
    const infoApoio = async () => {
      const { data } = await getApoio()
      setApoio(data as IGetApoioResponse)
    }
    infoApoio()
  }, [])

  const slidesPerView = isMobile ? 1 : 3
  const spaceBetween = isMobile ? 0 : 20

  const cardPlans = () => {
    return apoio?.plans.map((plan) => {
      return (
        <SwiperSlide key={plan.id}>
          <Card.Root feature={plan.feature} text={plan.feature_text}>
            <Card.Icon>{plan.icon}</Card.Icon>
            <Card.Title>{plan.title}</Card.Title>
            <Card.Benefits benefits={plan.benefits} />
            <Card.Price price={plan.price} />
            <Card.Actions assign={plan.assign} title={plan.title} />
          </Card.Root>
        </SwiperSlide>
      )
    })
  }

  if (!apoio) return null

  return (
    <main className={'w-full max-w-screen-xl m-auto'}>
      <HeadTitle>Apoio</HeadTitle>
      <Logo />
      <SocialLink socialLinks={apoio.social_links} />
      <Video
        videoId={apoio.video.id}
        videoTitle={apoio.video.description}
        buttonTitle={apoio.video.button_title}
      />
      <Goal meta={apoio.meta} />
      <section className={'mb-12'}>
        <Title>Planos</Title>
        <Swiper
          modules={[Navigation, EffectFade]}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          navigation={true}
          className={'swiper-support'}
        >
          {cardPlans()}
        </Swiper>
      </section>
      <section className={'flex flex-wrap gap-y-5 m-auto justify-center'}>
        <Title>Informações complementares</Title>
        <Details details={apoio.faq} />
      </section>
      <Copyright>Copyright © Rik. Todos os direitos reservados.</Copyright>
    </main>
  )
}

export default Support
