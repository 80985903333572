import { FC } from 'react'

import { formatReal } from '@utils/formats/formats'

import { CardPriceProps } from './CardInterface'

const CardPrice: FC<CardPriceProps> = ({ price }) => {
  return (
    <div
      className={'font-bold text-4xl mb-3.5'}
      itemProp="offers"
      itemScope
      itemType="https://schema.org/Offer"
    >
      <span className={'font-light text-sm mr-1.5'}>R$</span>
      <span itemProp="price">{formatReal(price)}</span>
      <span className={'font-light text-sm'}>/mês</span>
      <meta itemProp="priceCurrency" content="BRL" />
    </div>
  )
}

export default CardPrice
