import { FC } from 'react'
import { Helmet } from 'react-helmet'

interface HeadTitleProps {
  children: string
}

const CardIcon: FC<HeadTitleProps> = ({ children }) => {
  return (
    <Helmet>
      <title>{children} - Rik</title>
    </Helmet>
  )
}

export default CardIcon
