import { FC } from 'react'
import ReactGA from 'react-ga4'

import { SocialLinkEnum, SocialLinkIcons, SocialLinkProps } from './SocialLink.type'

const SocialLink: FC<SocialLinkProps> = ({ socialLinks }) => {
  const socialIcon = (type: SocialLinkEnum) => SocialLinkIcons[type]
  const socialLink = () => {
    return socialLinks.map((socialLink) => {
      const LucideIcon = socialIcon(socialLink.type)
      return (
        <li key={socialLink.id}>
          <a
            href={socialLink.url}
            title={socialLink.description}
            className={
              'w-9 h-9 flex border rounded p-1 hover:bg-white hover:text-violet-800 hover:border-violet-800'
            }
            target={'_blank'}
            rel={'noreferrer'}
            onMouseDown={() => eventsGA(socialLink.type)}
          >
            <LucideIcon />
          </a>
        </li>
      )
    })
  }

  const eventsGA = (type: SocialLinkEnum) => {
    ReactGA.event({
      category: 'social-link',
      action: 'click',
      label: type,
    })
  }

  return (
    <section className={'mb-8'}>
      <ul className={'flex text-white gap-2 justify-center'}>{socialLink()}</ul>
    </section>
  )
}

export default SocialLink
